import React from 'react'
import "./style.css"
import Card from './card'
import BannImg from "./bannerimg/index"

const Banner = () => {
  return (
    <>
      <BannImg />
      <Card />
      
      
    </>
  )
}

export default Banner

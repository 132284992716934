import React from 'react'

const index = () => {
  return (
    <div className='banner' >
          <div className='container banner-section ' > 
         <h1 className='banner-section__heading' > The leading voice in nutrition and dietetics</h1>
      </div>
      
    </div>
  )
}

export default index


const menuItems = [
 
    { name: 'Health advice',  link: '#' },
    { name: 'Seeing a dietitian', link: '#' },
    { name: "Working in dietetics", link: '#' },
    { name: 'Advocacy and policy', link: '#' }, 
    {name:'About us', link:'#'}
    
  ];
   export default menuItems